import React, { Component } from "react";
import {
  Divider,
  Button,
  Row,
  Card,
  Col,
  Table,
  Modal,
  Select,
  Image,
  Tag,
  message,
} from "antd";
import CustomLayout from "../../components/CustomLayout";
import { Spacer } from "../../components/global";
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import {
  getMedicalFolder,
  getMedicalFolderPhoto,
  getUser,
} from "../../services/users.services";
import Moment from "react-moment";
import {
  getBalise,
  setBaliseAlert,
  unsetBaliseAlert,
  unsetBaliseGeoAlert,
} from "../../services/balises.services";

const { confirm } = Modal;
const { Option } = Select;

class DetailUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: "",
      balises_associes: [],
      dossier_medical_photos: [],
      activatedBalises: [],
      selectedService: "18",
      userLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({ user_id: this.props.match.params.id });

    // Getting user detailed informations
    getUser(this.props.match.params.id).then((res) => {
      console.log("user", res);
      this.setState({ user: res?.user, userLoaded: true });
      res?.user?.balises_associes.map((balise) =>
        getBalise(balise?.id_balise).then((res) => {
          var tmp = this.state.balises_associes;
          var activatedBalises = this.state.activatedBalises;
          tmp.push(res?.balise);
          if (res?.balise?.en_alerte) activatedBalises.push(balise?.id_balise);
          this.setState({ balises_associes: tmp, activatedBalises });
        })
      );
    });

    // Getting Medical Folder
    getMedicalFolder(this.props.match.params.id).then((res) => {
      this.setState({ dossier_medical: res?.dossier_medical });
    });

    // Getting Medical Folder Photos
    //for (var i = 1; i < 4; i++) {
      getMedicalFolderPhoto(this.props.match.params.id, 4).then((res) => {
        var tmp = this.state.dossier_medical_photos;
        tmp.push(res);
        this.setState({ dossier_medical_photos: tmp });
      });
    //}
  }

  fetchBalises = async () => {
    var tmp = [];
    this.state.user?.balises_associes.map((balise) =>
      getBalise(balise?.id_balise).then((res) => {
        var activatedBalises = this.state.activatedBalises;
        tmp.push(res?.balise);
        if (res?.balise?.en_alerte) activatedBalises.push(balise?.id_balise);
        this.setState({ balises_associes: tmp, activatedBalises });
      })
    );
  };

  handleEnable(balise_id) {
    var data = {
      // id_user: balise.id_user_for_who,
      id_user: this.props.match.params.id,
      proximity_of_agressor: false,
      selected_service: this.state.selectedService,
    };

    const onOk = () => {
      setBaliseAlert(balise_id, data).then((res) => {
        console.log("res", res);
        if (!res.error) message.success("Balise activée avec succès");
        else this.fetchBalises();
      });
    };

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <h3>Déclenchement de la balise</h3>
          <Select
            defaultValue="18"
            placeholder="Choix du type de secours"
            style={{ width: "90%" }}
            onChange={(selectedService) => this.setState({ selectedService })}
          >
            <Option value="18">Pompier</Option>
            <Option value="15">SAMU</Option>
            <Option value="17">Forces de l'ordre</Option>
          </Select>
        </div>
      ),
      onOk,
      onCancel() {
        console.log("canceled");
      },
    });
  }

  handleDisable(balise_id) {
    const balise = this.state.balises_associes.find(
      (balise) => balise?.id === balise_id
    );

    const onOk = () => {
      if (balise?.appkey)
        unsetBaliseAlert(balise_id).then((res) => {
          console.log(res);
          if (!res.error) message.success("Balise désactivée avec succès");
          this.fetchBalises();
        });
      else
        unsetBaliseGeoAlert(balise_id).then((res) => {
          console.log(res);
          if (!res.error) message.success("Balise désactivée avec succès");
          this.fetchBalises();
        });
    };

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <h3>Désactivation de la balise</h3>
        </div>
      ),

      onOk,
      onCancel() {},
    });
  }

  render() {
    const { user, dossier_medical } = this.state;

    const columns = [
      {
        title: "Nom",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Début",
        dataIndex: "start",
        key: "start",
      },
      {
        title: "Fin",
        dataIndex: "end",
        key: "end",
      },
    ];

    const namedCol = [
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
    ];

    const antCol = [
      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
    ];

    const preventCol = [
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
        hidden: true,
      },

      {
        title: "",
        dataIndex: "id",
        key: "id",
        hidden: true,
      },

      {
        title: "Nom",
        dataIndex: "nom",
        key: "nom",
      },
      {
        title: "Prénom",
        dataIndex: "prenom",
        key: "prenom",
      },
      {
        title: "Téléphone",
        dataIndex: "telephone",
        key: "telephone",
        render: (text, record) => {
          return (
            <span>
              {text}
              {record.contact_pro && (
                <Tag size="small" style={{ marginLeft: 20 }}>
                  Pro
                </Tag>
              )}
            </span>
          );
        },
      },
    ].filter((item) => !item.hidden);

    const baliseCol = [
      {
        title: "",
        dataIndex: "id",
        key: "id",
        hidden: true,
      },
      {
        title: "ID Helpp-Life",
        dataIndex: "id_helpp_life",
        key: "id_helpp_life",
      },
      {
        title: "Adresse",
        dataIndex: "addresse",
        key: "addresse",
      },
      {
        title: "",
        dataIndex: "actions",
        key: "actions",
        responsive: ["sm"],

        render: (_, record) => (
          <>
            <Row gutter={[24, 10]}>
              {this.state.activatedBalises.includes(record.id) ?
              <Button
                style={{ marginRight: 10, width: 100 }}
                onClick={() =>
                  this.props.history.push(`/warnings/detail/${record.id}`)
                }
              >
                Détails
              </Button> : null }
              <Button
                style={{ width: 100 }}
                type="primary"
                danger={this.state.activatedBalises.includes(record.id)}
                onClick={() =>
                  this.state.activatedBalises.includes(record.id)
                    ? this.handleDisable(record.id)
                    : this.handleEnable(record.id)
                }
              >
                {this.state.activatedBalises.includes(record.id)
                  ? "Arrêter"
                  : "Déclencher"}
              </Button>
            </Row>
          </>
        ),
      },
    ].filter((v) => !v.hidden);

    return (
      <div>
        <CustomLayout selectedKey="users">
          <Row justify="start" align="middle" gutter={[6, 12]}>
            <Button
              shape="circle"
              icon={<LeftOutlined />}
              onClick={() => this.props.history.goBack()}
            />
            <h1 style={{ margin: 0, marginLeft: 20 }}>
              Utilisateur {user?.id_helpp_life}
            </h1>
          </Row>
          <Divider />
          <Spacer />
          <Row gutter={[24, 12]}>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Card title={<h2 style={{ margin: 0 }}>Détails</h2>}>
                <p>
                  <b>Nom:</b>&nbsp;
                  {user?.nom}
                  <br />
                  <b>Prénom:</b>&nbsp;
                  {user?.prenom}
                  <br />
                  <b>Sexe:</b>&nbsp;
                  {user?.sexe > 1 ? "Féminin" : "Masculin"}
                  <br />
                  <b>Date de naissance:</b>&nbsp;
                  <Moment format="DD/MM/YYYY">{user?.date_naissance}</Moment>
                  <br />
                  <b>Téléphone:</b>&nbsp;
                  {user?.telephone}
                  <br />
                </p>
                <p>
                  <b>Adresse:</b>
                  <br />
                  <b>Numéro:</b>&nbsp;
                  {user?.adresse?.adresse_numero}
                  <br />
                  <b>Complément numéro:</b>&nbsp;
                  {user?.adresse?.adresse_complement_numero}
                  <br />
                  <b>Rue:</b>&nbsp;
                  {user?.adresse?.adresse_rue}
                  <br />
                  <b>Complément de rue:</b> {user?.adresse?.adresse_complement}
                  <br />
                  <b>Numéro de porte:</b> {user?.adresse?.adresse_numero_porte}
                  <br />
                  <b>Etage:</b> {user?.adresse?.adresse_etage}
                  <br />
                </p>
                <p>
                  <b>Code Postal:</b> {user?.adresse?.adresse_code_postal}
                  <br />
                  <b>Ville:</b> {user?.adresse?.adresse_ville}
                  <br />
                  <b>Pays:</b> {user?.adresse?.adresse_pays}
                  <br />
                </p>
                <p>
                  <b>Informations complémentaires:</b>
                  <br />
                  {user?.adresse?.adresse_points_remarquables}
                </p>
                {/* <p>
                  <b>Coordonnées:</b>
                  <br />
                  Lat: 45.3879
                  <br />
                  Lon: 5.49088
                  <br />
                  <a
                    href={`https://www.google.com/maps/place/45,5/`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Liens Google Maps
                  </a>
                </p> */}
                <Divider />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
              <Card title={<h2 style={{ margin: 0 }}>Photos</h2>}>
                <Row gutter={24} justify="start">
                  {this.state.dossier_medical_photos?.map((image) => {
                    return (
                      <Col>
                        <Image src={image} width={200} />
                      </Col>
                    );
                  })}
                </Row>
              </Card>
            </Col>

            <Col md={24} lg={12} xl={12}>
              <Card title={<h2 style={{ margin: 0 }}>Personnes à prévenir</h2>}>
                {this.state.user &&
                  this.state.userLoaded &&
                  this.state.user.contacts && (
                    <Table
                      columns={preventCol}
                      dataSource={[
                        {
                          nom: this.state.user?.contacts[0]?.nom ?? "...",
                          prenom: this.state.user?.contacts[0]?.prenom,
                          telephone: this.state.user?.contacts[0]?.telephone,
                          contact_pro:
                            this.state.user?.contacts[0]?.contact_pro,
                        },
                        {
                          nom: this.state.user?.contacts[1]?.nom ?? "...",
                          prenom: this.state.user?.contacts[1]?.prenom,
                          telephone: this.state.user?.contacts[1]?.telephone,
                          contact_pro:
                            this.state.user?.contacts[1]?.contact_pro,
                        },
                        {
                          nom: this.state.user?.contacts[2]?.nom ?? "...",
                          prenom: this.state.user?.contacts[2]?.prenom,
                          telephone: this.state.user?.contacts[2]?.telephone,
                          contact_pro:
                            this.state.user?.contacts[2]?.contact_pro,
                        },
                      ]}
                      scroll={{ x: 600 }}
                    />
                  )}
              </Card>
              <Spacer height="10px" />

              <Card>
                <h2 style={{ margin: 0 }}>
                  <b>
                    Groupe sanguin :{" "}
                    <span style={{ color: "red" }}>
                      {dossier_medical?.groupe_sanguin}
                    </span>
                  </b>
                </h2>
              </Card>

              <Spacer height="10px" />
              <Card title={<h2 style={{ margin: 0 }}>Handicap</h2>}>
                <h4 style={{ fontWeight: 900, fontSize: "16px", color: "red" }}>
                  <b>
                    Sourd/Muet:&nbsp;
                    {dossier_medical?.sourd_muet ? "OUI" : "NON"}
                  </b>
                </h4>
                <Divider />
                <h4>
                  <b>Autres handicaps :</b>
                </h4>
                <p>{dossier_medical?.handicap}</p>
              </Card>
            </Col>

            <Col md={24} lg={24} xl={24}>
              <Card title={<h2 style={{ margin: 0 }}>Dossier médical</h2>}>
                <Row gutter={[24, 24]}>
                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 100 }}
                      columns={columns}
                      dataSource={dossier_medical?.maladies.map((maladie) => {
                        return {
                          name: maladie?.nom_maladie,
                          start: (
                            <Moment format="DD/MM/YYYY">
                              {maladie?.date_maladie}
                            </Moment>
                          ),
                          end: (
                            <Moment format="DD/MM/YYYY">
                              {maladie?.date_fin_maladie}
                            </Moment>
                          ),
                        };
                      })}
                      title={() => <h3 style={{ margin: 0 }}>Maladies</h3>}
                      bordered
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 100 }}
                      columns={namedCol}
                      dataSource={dossier_medical?.allergies?.map(
                        (allergie) => {
                          return {
                            nom: allergie?.nom_allergie,
                          };
                        }
                      )}
                      title={() => <h3 style={{ margin: 0 }}>Allergies</h3>}
                      bordered
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 100 }}
                      columns={columns}
                      dataSource={dossier_medical?.hospitalisations?.map(
                        (hospitalisation) => {
                          return {
                            name: hospitalisation?.nom_hospitalisation,
                            start: (
                              <Moment format="DD/MM/YYYY">
                                {hospitalisation?.date_hospitalisation}
                              </Moment>
                            ),
                            end: (
                              <Moment format="DD/MM/YYYY">
                                {hospitalisation?.date_fin_hospitalisation}
                              </Moment>
                            ),
                          };
                        }
                      )}
                      title={() => (
                        <h3 style={{ margin: 0 }}>Hospitalisations</h3>
                      )}
                      bordered
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 100 }}
                      columns={antCol}
                      dataSource={dossier_medical?.antecedents?.map(
                        (antecedent) => {
                          return {
                            nom: antecedent?.description_antecedent,
                            date: (
                              <Moment format="DD/MM/YYYY">
                                {antecedent?.date_antecedent}
                              </Moment>
                            ),
                          };
                        }
                      )}
                      title={() => <h3 style={{ margin: 0 }}>Antécédents</h3>}
                      bordered
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Table
                      pagination={false}
                      scroll={{ x: 600, y: 100 }}
                      columns={namedCol}
                      dataSource={dossier_medical?.traitement_en_cours?.map(
                        (traitement) => {
                          return {
                            nom: traitement?.description_traitement_en_cours,
                          };
                        }
                      )}
                      title={() => <h3 style={{ margin: 0 }}>Traitement</h3>}
                      bordered
                    />
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <h3>Remarques</h3>
                    <p style={{ height: 150, overflowY: "scroll" }}>
                      {dossier_medical?.remarque}
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col md={24} lg={24} xl={24}>
              <Card
                title={
                  <Row justify="space-between" align="middle">
                    <h2 style={{ margin: 0 }}>Balises de l'utilisateur</h2>
                  </Row>
                }
              >
                <Table
                  scroll={{ x: 600 }}
                  columns={baliseCol}
                  dataSource={this.state.balises_associes?.map(
                    (balise, index) => {
                      const {
                        adresse_numero,
                        adresse_rue,
                        adresse_code_postal,
                        adresse_ville,
                        adresse_pays,
                      } = balise?.adresse;

                      const filtered_address =
                        adresse_numero +
                        " " +
                        adresse_rue +
                        " " +
                        adresse_code_postal +
                        " " +
                        adresse_ville +
                        " " +
                        adresse_pays;

                      return {
                        id: balise?.id,
                        key: balise?.id + index,
                        id_helpp_life: balise.id_helpp_life,
                        addresse: filtered_address,
                      };
                    }
                  )}
                />
              </Card>
            </Col>
          </Row>
        </CustomLayout>
      </div>
    );
  }
}

export default DetailUser;
