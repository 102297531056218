import React, { Component } from "react";
import styled from "styled-components";
import { Divider } from "antd";

import Logo from "../../assets/images/logo.png";
import {getScannedTag, searchUsers} from "../../services/users.services";

class QRPage extends Component {
  state = {
    tagId: '',
    tagInfo: '',
    tagUserName: ''
  }

  componentDidMount() {
    console.log(this.props.match.params.id);
    var accessToken = localStorage.getItem("accessToken");
    if (accessToken !== null && accessToken !== undefined) {
      getScannedTag(this.props.match.params.id).then((tag) => {
        console.log(tag);
        searchUsers(tag.id_helpp_life).then((r) => {
          if (r.users && r.users.length > 0) {
            console.log(r.users[0]);
            window.location.pathname = `/users/${r.users[0].id}`;
          }
        });
      }).catch(() => {
        this.setState({
          tagId: 'Tag Inconnu',
          tagInfo: '',
        });
      });
      //window.location.pathname = `/users/${this.props.match.params.id}`;
    }
    else {
      getScannedTag(this.props.match.params.id).then((tag) => {
        console.log(tag);
        this.setState({
          tagId: tag.id_helpp_life,
          tagInfo: tag.donnees_publiques,
          tagUserName: tag.nom + ' ' + tag.prenom
        });
      }).catch(() => {
        this.setState({
          tagId: 'Tag Inconnu',
          tagInfo: '',
          tagUserName: ''
        });
      });
    }
  }

  render() {
    return (
      <>
        <LoginWrapper>
          <CardWrapper>
            <center>
              <img src={Logo} alt="" style={{ height: 40, margin: 0 }} />
              <h2 style={{ margin: 10, fontWeight: 600 }}>Données protégées</h2>
            </center>
            <Divider />
            {this.state.tagId === '' ? <div style={{ padding: "0 10%", textAlign: "center" }}><h2>Chargement du dossier médical...</h2></div> :
                (
                    <>
                      <div style={{ padding: "0 10%", textAlign: "center" }}>
                        <h2>
                          Appelez le <strong style={{color: 'red'}}>112</strong>
                          <br />
                          <br />
                          Identifiant utilisateur : <strong style={{textDecoration: 'underline'}}>{this.state.tagId}</strong>
                        </h2>
                        {this.state.tagInfo ? (
                            <>
                              <Divider />
                              <h3 style={{ margin: 0, textTransform: 'uppercase', textDecoration: 'underline', fontWeight: 600 }}>
                                Données publiques
                              </h3>
                              <p style={{ marginTop: 8, marginBottom: 0, color: "black", fontSize: 18 }}>
                                <strong>{this.state.tagUserName}</strong>
                              </p>
                              <p style={{ marginTop: 0, color: "red", fontSize: 18 }}>
                                {this.state.tagInfo}
                              </p>
                            </>
                          ) : null }
                      </div>
                    </>
                )}
          </CardWrapper>
        </LoginWrapper>
      </>
    );
  }
}

export default QRPage;

const LoginWrapper = styled.div`
  background: #000;
  position: relative;
  padding: 20px;
  min-height: ${window.innerHeight}px;
  // max-height: 100%;
  color: white;

  h1 {
      text-align: center;
      font-weight: 800;
      margin: 0
  }
`;

const CardWrapper = styled.div`
  background: white;
  padding: 20px;
  width: 95%;
  max-width: 768px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
