import axios from "axios";
import { API_URL, LOGIN_URL } from "../config";
import { catchError } from "./response";

const getApiRequestService = (api = false) => {
  return axios.create({
    baseURL: `${api ? API_URL : LOGIN_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: ` ${
        localStorage.getItem("accessToken") !== null &&
        `Bearer ${localStorage.getItem("accessToken")}`
      }`,
    },
  });
};

export const loginUser = async (body: Record<string, string>) => {
  try {
    const res = await getApiRequestService().post("", body);

    return res.data;
  } catch (error) {
    console.log("LOGIN_USER", error);

    return catchError(error);
  }
};

export const resetPassword = async (email: string) => {
  try {
    const res = await axios
      .create({
        baseURL: "https://api.societe.helpp-life.fr",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Authorization: ` ${
            localStorage.getItem("accessToken") !== null &&
            `Bearer ${localStorage.getItem("accessToken")}`
          }`,
        },
      })
      .get(`/sendResetLink/${email}`);

    return res.data;
  } catch (error) {
    console.log("RESET_PASSWORD", error);

    return catchError(error);
  }
};
